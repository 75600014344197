* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
:root {
  --grey: #ececec;
  --dungeon-clr: #30b4fe;
  --factory-clr: #ffdb00;
}
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  height: 100dvh;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
}
.home-page {
  position: relative;
  z-index: 6;
  color: var(--grey);
  pointer-events: none;
  padding: 3rem 1rem 2rem 1rem;
  overflow: hidden;
  height: 100%;
  align-items: end;
}
h1,
h2 {
  text-align: center;
  font-weight: 400;
  margin: 0;
}

h1 {
  margin-bottom: 50px;
  margin-top: 90px;
  font-size: 40px;
}
h3 {
  margin-bottom: 0;
  margin-top: 30px;
  font-size: 17px;
  color: #ebc137;
}
ul {
  list-style: none;
  display: flex;
  gap: 2rem;
}
.grid-center {
  display: grid;
  justify-content: center;
  justify-items: center;
}
.gallery {
  /* margin-top: 20%;
  margin-bottom: 1rem; */
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}
.choose {
  font-size: 40px;
}

.theme-choise-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-block: 10px;
}

.theme-choise {
  width: 350px;
  height: 115px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 900;
  font-size: 26px;
  font-family: sans-serif;
  font-style: italic;
  color: black;
  background-position-y: -20px;
  background-position-x: -5px;
  background-size: 450px 200px;
  background-repeat: no-repeat;
  transform: skewX(-15deg);
}

/* .dungeon,
.factory {
  box-shadow: 0px 0px 30px rgba(245, 245, 245, 0.1);
} */

.dungeon > .theme-choise {
  -webkit-text-stroke: 1px var(--dungeon-clr);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../static/assets/dungeonBg.jpg');
  border: 4px solid var(--dungeon-clr);
}

.dungeon > .theme-choise:hover,
.dungeon > .theme-choise:focus {
  color: var(--dungeon-clr);
  text-shadow: 0px 0px 20px white;
}

.factory > .theme-choise {
  -webkit-text-stroke: 1px var(--factory-clr);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../static/assets/factory-bg.jpg');
  border: 4px solid var(--factory-clr);
}
.factory > .theme-choise:hover,
.factory > .theme-choise:focus {
  color: var(--factory-clr);
  text-shadow: 0px 0px 20px white;
}

#root {
  width: 100%;
  height: 100vh;
}
canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  pointer-events: none;
  background-size: 100% 100%;
}
.mainBg {
  z-index: 1;
  pointer-events: auto;
}
.minimal-p {
  align-self: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.column {
  flex-direction: column;
}
.flex {
  display: flex;
}
.footer {
  gap: 0.85rem;
  margin-top: auto;
  flex-direction: column;
}
.name {
  gap: 0.5rem;
}

.loader {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
}
.welcome {
  text-align: center;
  font-size: 22px;
  align-self: start;
}
.welcome span {
  color: gold;
  font-size: 16px;
}
.minimal-link {
  color: #ffbb4a;
  text-decoration: none;
}
.minimal-link:hover,
.minimal-link:focus {
  color: #ffdfad;
  text-decoration: underline;
}

.socials {
  display: flex;
  gap: 1rem;
}
.socials a {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  width: 20px;
  font-size: 15px;
  border-radius: 50%;
  outline: 1px solid var(--grey);
  outline-offset: 4px;
  color: var(--grey);
  text-decoration: none;
}
.socials a:hover,
.socials a:focus {
  color: #b3b3b3;
}
@media (min-width: 551px) and (max-width: 800px) {
  .theme-choise {
    width: 250px;
    height: 115px;
    font-size: 25px;
    background-position-x: -80px;
    background-position-y: -10px;
    background-size: 420px 150px;
  }
  .choose {
    font-size: 40px;
  }
  .dungeon .theme-choise {
    border-width: 3px;
  }
  .factory .theme-choise {
    border-width: 3px;
  }
}
@media (max-width: 550px) {
  .gallery {
    margin-top: 9vh;
    margin-top: 9dvh;
  }
  .footer {
    gap: 1rem;
    flex-direction: row;
  }
  .home-page {
    padding-top: 2rem;
  }
  .choose {
    font-size: 30px;
  }
  .cards {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    padding-bottom: 1rem;
  }
  .theme-choise {
    height: 90px;
    width: 100%;
    background-position-x: -90px;
    background-position-y: -26px;
    background-size: 420px 150px;
    font-size: 20px;
  }
  .dungeon .theme-choise {
    border-width: 2px;
  }
  .factory .theme-choise {
    border-width: 2px;
  }
  .theme-choise-container {
    padding: 0;
  }
  .minimal-p {
    font-size: 13px;
    width: 80%;
    text-align: center;
  }
  .minimal-link {
    font-size: 17px;
  }
  .socials a {
    font-size: 13px;
    width: 16px;
  }
  .name div {
    text-align: center;
    font-size: 14px;
  }
  .name {
    flex-direction: column;
  }
}
